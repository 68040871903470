.ExpertAppointmentDashboard {
  .ExpertAppointmentDashboard--Header {
    flex-direction: column;

    & > div {
      justify-content: space-between;

      .ViewCalendar {
        background: var(--primary-base-color);
        display: flex;
        gap: 5px;
        box-shadow: none;
        padding: 8px 24px;
        border-radius: 10px;
        font-size: 14px;
        text-transform: capitalize;
        font-size: 14px;
      }
    }
    h2 {
      color: #000;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    .Options {
      .Option--Item {
        cursor: pointer;
        color: #6a6c6a;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .SelectedItem {
        background: #ffffff;
        color: #212529;
      }
    }

    .Selectors {
      align-items: center;
      margin-left: auto;
      p {
        color: #656565;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
      }
    }
  }

  .MuiSelect-select {
    min-width: 70px;
    min-height: 0;
    padding: 13.5px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .AppointmentCalendarView {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: -16px;
  }
}

.CalendarView--Content {
  .rbc-header {
    min-height: 42px;
    border: 0 !important;
  }

  .rbc-events-container {
    overflow: hidden;
    margin-right: 0 !important;
  }

  .rbc-event {
    background-color: #fff !important;
    color: var(--primary-accent-color) !important;
    //border: 1px solid #3174ad !important;
    //width: max-content !important;
    height: max-content !important;
  }
  .rbc-day-slot .rbc-event-label {
    display: none;
  }
  .rbc-btn-group {
    border: 0;
    padding: 4px;
    background: #f0f0f0;
    border-radius: 10px;
  }
  .rbc-btn-group button {
    border: 0;
    color: #212529;
    border-radius: 10px !important;
    :focus {
      background: #ffffff;
      color: #212529;
    }
  }

  p {
    color: #000;
  }

  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    background: #ffffff;
    color: #212529;
  }

  .rbc-toolbar button.rbc-active {
    background: #ffffff;
    color: #212529;
    box-shadow: none;
  }

  .rbc-month-view {
    border: 0;

    .rbc-month-header {
      border-bottom: 1px solid #dddddd;
    }
  }
}

.AppointmentCalendarView--Component {
  padding: 22px 10px 40px 20px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 20px;
  .AppointmentCalendarView--Header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiFormControl-root {
      width: 128px;
      border-radius: 15px;
      background-color: #e7f6ff;
    }
    h6 {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
    span {
      color: #656565;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }

  .Calendar--Section {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 10px;

    .date-button {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      justify-content: center;
      width: 6px;
      margin: 0 auto;
      border-radius: 10px;
      .day {
        color: #21231e;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .date {
        color: #21231e;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .indicator {
        padding: 2px;
        border-radius: 50%;
        background-color: #000;
      }
    }
    .isActive {
      border: 1px solid #73589b;
      background-color: #e7f6ff;
    }
    .inactive {
      .day,
      .date {
        color: #929292;
      }
      .indicator {
        background-color: #929292;
      }
    }
  }

  .Appointment--Item {
    background-color: #f5f5f5;
  }
  .Completed--Item {
    background-color: #fff;
  }
}
.Appointments--Container {
  display: flex;
  flex-direction: column !important;
  gap: 10px;

  .Appointment--Item {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    gap: 10px;
    .Item--Details {
      flex: 1;
      display: flex;
      flex-direction: column;
      h6 {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        svg {
          color: #d2293d;
          width: 15px;
          height: 15px;
          flex-shrink: 0;
        }
      }
      span {
        color: #55a51d;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.21px;
      }
      p {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.21px;
      }
    }
    .CTA--Container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      button {
        background: #f5f5f5;
        padding: 8px 15px;
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 20px;
        color: #414141;
        font-size: 14px;
        flex: 1;
        svg {
          width: 16px;
          height: 16px;
        }
        p {
          line-height: 130%;
          text-transform: capitalize;
        }
        &:last-child {
          padding: 8px;
          min-width: 0;
          aspect-ratio: 1/1;
          flex: 0.25;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
