.LocationModal > {
  .Centered-location-modal > {
    .modal-dialog {
      max-width: 800px !important;
      background-color: #fff;
    }
  }

  .Centered-location-modal > {
    .modal-body {
      background-color: #fff !important;
    }
  }

  .line {
    flex: 1;
    border-bottom: 1px solid #ebebeb; /* Adjust the style of the line as needed */
    margin: 0 10px;
  }

  .or {
    text-align: center;
    color: #747474;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  //   .modal-lg,
  //   .modal-xl {
  //     width: 700px !important;
  //   }
}

.line {
  flex: 1;
  border-bottom: 1px solid #ebebeb; /* Adjust the style of the line as needed */
  margin: 0 10px;
}

.or {
  text-align: center;
  color: #747474;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
