@import "./styles/dashboard/NavigationRail.scss";

@import "./styles/dashboard/Widgets.scss";

@import "./styles/dashboard/Notifications.scss";
@import "./styles/dashboard/ExpertDashboard.scss";
@import "./styles/dashboard/Inbox.scss";
@import "./styles/dashboard/Calendar.scss";

@import "./styles/dashboard/ExpertAppointment.scss";
@import "./styles/dashboard/ExpertClientDashboard.scss";
@import "./styles/dashboard/ExpertScheduleDashboard.scss";
@import "./styles/dashboard/ExpertWorksheetDashboard.scss";
@import "./styles/dashboard/ExpertArticlesDashboard.scss";
@import "./styles/dashboard/ExpertPosts.scss";
@import "./styles/dashboard/ExpertNotifications.scss";
@import "./styles/dashboard/ExpertHelpCenter.scss";
@import "./styles/dashboard/ExpertSettings.scss";
@import "./styles/dashboard/ExpertChatDashboard.scss";
@import "./styles/dashboard/ExpertSessionManagement.scss";
@import "./styles/dashboard/SessionTranscriptScrollbar.scss";

/*Cards*/
@import "./styles/cards/UpcomingAppointmentCard.scss";
@import "./styles/cards/NotificationRowCard.scss";
@import "./styles/cards/InboxRowCard.scss";
@import "./styles/cards/AppointmentCard.scss";
@import "./styles/cards/WorksheetCard.scss";
@import "./styles/cards/ArticleCard.scss";
@import "./styles/cards/PayoutWidget.scss";

//Tables
@import "./styles/tables/ClientDetailsTable.scss";
@import "./styles/tables/TicketDetailsTable.scss";
@import "./styles/tables/PayoutDetailsTableRow.scss";

//Dialogs
@import "./styles/dialogs/RescheduleDialog.scss";
@import "./styles/dialogs/NotesDialog.scss";
@import "./styles/dialogs/PrescriptionDialog.scss";
@import "./styles/dialogs/CancelDialog.scss";
@import "./styles/dialogs/AddAppointmentDialog.scss";

//Common
@import "./styles/common/CTA.scss";
@import "./styles/common/Background.scss";
@import "./styles/common/Icons.scss";
@import "./styles/common/Dropdowns.scss";
@import "./styles/common/Dialog.scss";
@import "./styles/common/ComingSoon.scss";
@import "./styles/common/Badge.scss";
@import "./styles/dashboard/NoDataAvailable.scss";
@import "./styles/common/Skeleton.scss";

//Animation
@import "./styles/common/Animation.scss";

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  //overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

// .Header--2{
//   .navbar-nav{
//     a {
//       font-size: 14px !important;
//       font-weight: 500 !important;
//     }
//   }
// }

:root {
  --primary-color: #73589b;
  --button-primary-color: #73589b;
  --secondary-color: #fff;
  --primary-gradient: linear-gradient(93deg, #05f18f -3.22%, #078a54 103.49%);
  --light-blue: #eeebf3;
  --primary-base-color: #002b6f;
  --primary-base-color-darken: #002155;
  --primary-accent-color: #002b6f15;
  --primary-base-opacity30: #002b6f30;
  --primary-base-opacity50: #002b6f50;
}

.primary-color {
  background: var(--primary-color);
}
.btn-primary {
  background-color: var(--primary-color);
}
.btn-primary-color {
  background: var(--primary-color);
}

* {
  &::selection {
    background-color: #ccb6ec;
  }
}

.MuiCheckbox-root {
  color: var(--primary-color) !important;
}
.modal-content {
  background-color: inherit;
  border-radius: 20px !important;
}

.courseStats--Container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  .courseStat--Item {
    grid-column: span 6 / span 6;
    @media screen and (min-width: 500px) {
      grid-column: span 3 / span 3;
    }
  }
}

.dashboardCourses--Container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
  .dashboardCourse--Item {
    width: 100%;
  }
}
.AssessmentCard--title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--primary-color);
}
.AssessmentCard--author {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.AssessmentList--Container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  width: 100%;
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  }
}
.AssessmentCard--question,
.AssessmentCard--author {
  color: #666;
  font-size: 14px;
}
.AssessmentCard--Button {
  background-color: var(--button-primary-color);
  &:hover {
    background-color: darken(#73589b, 8%);
  }
}
.pagination__link:hover {
  color: #73589b !important;
}
.btn-outline-primary {
  color: #73589b !important;
  border-color: #73589b !important;
  &:hover {
    background-color: #73589b !important;
    color: #fff !important;
  }
}
.btn-outline-primary.activeprimary {
  color: #fff !important;
}
.btn-success {
  background-color: #6ad167 !important;
  border: none !important;
}
.btn-danger {
  background-color: #f9606f !important;
  border: none !important;
}
.Confirmation--Dialog {
  .MuiDialog-paper {
    max-width: 600px;
    width: 100%;
    .MuiDialogContentText-root {
      color: #525252;

      font-size: 0.95rem;
    }
    .MuiDialogActions-root {
      padding: 0 15px 20px 15px;
      button {
        height: 45px;
        flex: 1;
        font-size: 0.9rem;
      }
    }
  }
}


.Centered-location-modal {
  .modal-body {
    background-color: #fff !important;
    border-radius: 20px;
  }
}