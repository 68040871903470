::-webkit-scrollbar {
  width: 5px !important;
}

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.expertDetail--container {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.nav-tabs .nav-link.active {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: var(--primary-color) !important;
  border-color: #fff;
  border-bottom-color: var(--primary-color) !important;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
  border: 1px solid #dee2e6 !important;
  border-bottom: 3px solid var(--primary-color) !important;
  border-radius: 8px 8px 0 0;
}

.nav-tabs .nav-link {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #798287 !important;
  white-space: nowrap !important;
}

.form-check {
  display: flex;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  align-items: center !important;
  gap: 5px;
}

.area_of_concern_selected:checked {
  background-color: var(--primary-color);
}

input[type="checkbox"]:checked {
  border-color: var(--primary-color);
  accent-color: var(--primary-color);
}

.slots-container {
  height: 208px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 500px) {
  .time-availability {
    width: 100px !important;
  }
}

.time-availability {
  width: 110px;
}

.tab-heading {
  min-height: 736px;
  overflow: auto;
}

@media screen and (min-width: 501px) {
  .tab-heading {
    height: 747px;
  }
}

@media screen and (max-width: 500px) {
  .tab-heading {
    min-height: 350px !important;
  }
}

.videoPagination > ul > li > button {
  border: 1px solid #e6e7eb;
  background: #fff;
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  /* 20.8px */
  width: 38px;
  height: 38px;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #73589b !important;
  color: #fff !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 130% !important;
  /* 20.8px */
}

.notFound-img {
  height: 475px;
  width: 475px;
}

@media screen and (max-width: 500px) {
  .notFound-heading {
    font-size: 24px !important;
  }

  .notFound-text {
    font-size: 16px !important;
  }

  .notFound-img {
    height: 200px !important;
    width: 200px !important;
  }

  .notFound-btn {
    font-size: 18px !important;
  }
}

.leftModal--body {
  height: 100vh;
  width: 100% !important;
  background-color: #FFF;
}

@media screen and (max-width: 500px) {
  .leftModal--body {
    height: 98vh !important;
  }
}
